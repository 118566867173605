import React, { useEffect, useContext } from 'react';
import { UserContext } from './WithAuth';

const GtagUserSetter = () => {
    const userData = useContext(UserContext);

    useEffect(() => {
        if (typeof window === 'undefined') return; // Ensure this code runs only on the client side
        if (userData && window.gtag && userData.cognitoSession) {
            const org_name = userData.cognitoSession.getIdToken().payload['custom:organization']
            const org_id = userData.cognitoSession.getIdToken().payload['custom:organization_id']
            const client_id = userData.cognitoSession.getIdToken().payload['custom:client_id']
            const sub = userData.cognitoSession.getIdToken().payload['sub']
            // Send info in pageview event until a full switch to GTM
            let userValues: any = {
                org_name: org_name,
                org_id: org_id,
                client_id: client_id,
                sub: sub,
            }
            if (org_name === 'popstox') {
                userValues = {
                    ...userValues,
                    'traffic_type': 'internal'
                }
            }
            window.gtag('event', 'page_view', userValues);
            window.gtag('set', {'user_properties': userValues});
        }
    }, [userData]);

    return null;
};

export default GtagUserSetter;
