import { useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import { UserContext } from './WithAuth';
import { useLocation } from '@reach/router';

let isMatomoInitialized = false;

const MatomoTrack = () => {
    const userData = useContext(UserContext);
    const location = useLocation();

    useEffect(() => {
        if (typeof window !== 'undefined' && !isMatomoInitialized) {
            if (typeof window._paq === 'undefined') {
                window._paq = window._paq || [];
            }
            window._paq.push(['setRequestMethod', 'POST']);

            // Initialize Matomo only once
            fetch('https://mato.runicinsights.com/runicmato.txt')
                .then(response => response.text())
                .then(encoded => {
                    const decoded = atob(encoded);
                    // Initialize Matomo...
                    var u = '//mato.runicinsights.com/';
                    window._paq.push(['setTrackerUrl', u + 'runicmat.php']);
                    window._paq.push(['setSiteId', '1']);
                    var d = document,
                        g = d.createElement('script'),
                        s = d.getElementsByTagName('script')[0];
                    g.type = 'text/javascript';
                    g.async = true;
                    g.defer = true;
                    // Set the decoded text directly as the script content
                    g.textContent = decoded;

                    s.parentNode.insertBefore(g, s);
                }).catch(
                    error => console.error('Analytics loading error:', error)
            );

            isMatomoInitialized = true;
        }
    }, []);

    useEffect(() => {
        if (typeof window === 'undefined' || typeof window._paq === 'undefined') {
            return;
        }

        // Set custom dimensions if user data is available
        if (userData && userData.cognitoSession) {
            const org_name = userData.cognitoSession.getIdToken().payload['custom:organization'];
            const org_id = userData.cognitoSession.getIdToken().payload['custom:organization_id'];
            const client_id = userData.cognitoSession.getIdToken().payload['custom:client_id'];
            const sub = userData.cognitoSession.getIdToken().payload['sub'];

            window._paq.push(['setCustomDimension', 1, org_name]);
            window._paq.push(['setCustomDimension', 2, org_id]);
            window._paq.push(['setCustomDimension', 3, client_id]);
            window._paq.push(['setCustomDimension', 4, sub]);
        }

        // Get the full URL including query parameters
        const currentUrl = `${location.pathname}${location.search}`;

        // If on the specific page, extract 'id' from query parameters
        if (location.pathname === '/dashboardview/') {
            const params = new URLSearchParams(location.search);
            const id = params.get('id');
            if (id) {
                window._paq.push(['setCustomDimension', 5, id]);
            }
        }

        // Set the custom URL and track the page view
        window._paq.push(['setCustomUrl', currentUrl]);
        window._paq.push(['setDocumentTitle', document.title]);
        window._paq.push(['trackPageView']);
        window._paq.push(['enableLinkTracking']);
    }, [location, userData]);

    return null;
};

export default MatomoTrack;
