import * as React from "react";
import type { PageProps } from "gatsby";
import ComingSoon from "../components/layout/ComingSoon";
import WithAuth from "../components/user/WithAuth";
import GtagUserSetter from "../components/user/GtagUserSetter";
import AmplitudeUserSetter from "../components/user/AmplitudeUserSetter";
import MatomoTrack from "../components/user/MatomoTrack";

const SettingsRoute = (props: PageProps) => {
  return (
      <WithAuth>
          <GtagUserSetter />
          <MatomoTrack />
          <AmplitudeUserSetter />
          <ComingSoon {...props} children={undefined} />
      </WithAuth>
  );
};

export const Head = () => (
  <>
    <title>Runic | My Settings</title>
  </>
)


export default SettingsRoute;
