import React, { useEffect, useContext } from 'react';
import { UserContext } from './WithAuth';

const AmplitudeUserSetter = () => {
    // const userData = useContext(UserContext);
    //
    // useEffect(() => {
    //     if (typeof window === 'undefined') return; // Ensure this code runs only on the client side
    //     if (userData && window.amplitude && userData.cognitoSession) {
    //         const org_name = userData.cognitoSession.getIdToken().payload['custom:organization']
    //         const org_id = userData.cognitoSession.getIdToken().payload['custom:organization_id']
    //         const client_id = userData.cognitoSession.getIdToken().payload['custom:client_id']
    //         const sub = userData.cognitoSession.getIdToken().payload['sub']
    //         window.amplitude.getInstance().setUserProperties({
    //             org_name: org_name,
    //             org_id: org_id,
    //             client_id: client_id,
    //             sub: sub,
    //         });
    //     }
    // }, [userData]);

    return null;
};

export default AmplitudeUserSetter;
